import QueryString from 'query-string'
import * as R from 'ramda'
import {demoUrl} from '~/config/app'
import {mercuryAppDomain, mercuryDomain} from '~/config/env.mjs'
import {isPresent} from '~/utils'

const mkMercuryEmail = (localPart: string) => `${localPart}@${mercuryDomain}`

export const mailto = (email: string, subjectLine?: string, ccEmail?: string) => {
  const queryParams: string = QueryString.stringify(
    R.reject(R.anyPass([R.isEmpty, R.isNil]))({
      subject: subjectLine,
      cc: ccEmail,
    })
  )
  return `mailto:${email}${isPresent(queryParams) ? '?' : ''}${queryParams}`
}

export const helpEmail = mkMercuryEmail(`help`)
export const helloEmail = mkMercuryEmail(`hello`)
export const supportEmail = mkMercuryEmail(`support`)
export const securityEmail = mkMercuryEmail(`security`)
export const jobsEmail = mkMercuryEmail(`jobs`)
export const onboardingEmail = mkMercuryEmail(`onboarding`)
export const treasuryEmail = mkMercuryEmail(`treasury`)
export const raiseEmail = mkMercuryEmail(`raise`)
export const capitalEmail = mkMercuryEmail(`capital`)
export const perksEmail = mkMercuryEmail(`perks`)
export const pressEmail = mkMercuryEmail(`press`)
export const relationshipManagerEmail = mkMercuryEmail(`rm`)
// NOTE: `vcEmail` used to be `vcquestions@` but was sunset and changed to bd@
// https://mercurytechnologies.slack.com/archives/C031P2SP814/p1684442090235019
export const vcEmail = mkMercuryEmail('bd')
export const investorDbEmail = mkMercuryEmail('database')

/** `https://mercury.com` */
export const mercuryUrl = `https://${mercuryDomain}`

export const mercuryAppUrl = `https://${mercuryAppDomain}`

/** `mercury.com` */
export const noProtocolMercuryUrl = mercuryDomain

export const noProtocolMercuryAppUrl = mercuryAppDomain

/** `www.mercury.com` */
export const noProtocolMercuryUrlWithWww = `www.${noProtocolMercuryUrl}`

/**
 * Shortcut for building HTTPS links to Mercury with some path added to it.
 * Most uses of this will utilize the `internalLinks` object's strings as the parameter.
 *
 * `prodUrlWithPath(internalLinks.faq) === 'https://mercury.com/faq'`
 */
export const mercuryUrlWithPath = (path: string) =>
  `${mercuryUrl}${path.startsWith('/') ? '' : '/'}${path}`

export const mercuryAppUrlWithPath = (path: string) =>
  `${mercuryAppUrl}${path.startsWith('/') ? '' : '/'}${path}`

export const demoUrlWithPath = (path: string) =>
  `${demoUrl}${path.startsWith('/') ? '' : '/'}${path}`

/**
 * Shortcut for building protocol-omitting URLs to Mercury with some path added to it.
 * For internal links, use mercuryDomain, for external links to mercury app, use
 * `mercuryAppDomain`.
 *
 * `noProtocolProdUrlWithPath(internalLinks.faq) === 'mercury.com/faq'`
 */
export const noProtocolMercuryUrlWithPath = (path: string) =>
  `${noProtocolMercuryUrl}${path.startsWith('/') ? '' : '/'}${path}`

export const noProtocolMercuryAppUrlWithPath = (path: string) =>
  `${noProtocolMercuryAppUrl}${path.startsWith('/') ? '' : '/'}${path}`

export const mercuryFormstackUrl = `https://mercury.formstack.com`
