/* eslint-disable import/no-duplicates */
'use client'

// copied from https://github.com/MercuryTechnologies/mercury-web/blob/0d08a24d019530db90c1c95dcf44db5ca9904387/src/components/Typography/index.tsx
import {faArrowDownToLine, faFileZipper} from '@fortawesome/pro-regular-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import * as ContextMenu from '@radix-ui/react-context-menu'
import classnames from 'classnames'
import React from 'react'
import {unreachableCase} from '~/design-system/utils'
import {arcadia} from '~/fonts/Arcadia'
import {externalLinks} from '~/routing/external-links'
import {internalLinks} from '~/routing/internal-links'
import {download} from '~/utils/fileDownloads'
import {NoStyleLink} from '../NoStyleLink'
import MercuryLogoWordmark from './_icons/mercury-logo-icon-and-wordmark.svg'
import styles from './styles.module.css'

const LOGO_DOWNLOAD_URL_PREFIX = '/icons/LogoDownloads'

type LogoAndBrandProps = {
  options: LogoAndBrandOptions
  className?: string
}

type LogoAndBrandKind =
  | 'white'
  | 'specific-referrer'
  | 'colored'
  | 'black'
  | 'inherit'

type LogoAndBrandOptionsBase = {
  kind: LogoAndBrandKind
}

type LogoAndBrandOptionWhite = {
  kind: 'white'
  clickable: 'home' | false
} & LogoAndBrandOptionsBase

type LogoAndBrandOptionColored = {
  kind: 'colored'
  clickable: 'home' | false
} & LogoAndBrandOptionsBase

type LogoAndBrandOptionBlack = {
  kind: 'black'
  clickable: 'home' | false
} & LogoAndBrandOptionsBase

type LogoAndBrandOptionInherit = {
  kind: 'inherit'
  clickable: 'home' | false
} & LogoAndBrandOptionsBase

export type LogoAndBrandOptions =
  | LogoAndBrandOptionWhite
  | LogoAndBrandOptionColored
  | LogoAndBrandOptionBlack
  | LogoAndBrandOptionInherit

export const LogoAndBrand = ({options, className}: LogoAndBrandProps) => {
  const clickable = options.clickable || undefined

  const colorClassName = ((): string => {
    switch (options.kind) {
      case 'inherit':
        return 'inherit'
      case 'white':
        return 'white'
      case 'black':
        return 'black'
      case 'colored':
        return options.kind
      default:
        return unreachableCase(options)
    }
  })()

  const wrapperDivClassNames = classnames(
    styles['logo-brand-wrapper'],
    styles[colorClassName],
    !!clickable && styles.clickable
    // options.easeColor && styles.easeColor
  )

  const logo = ((): React.ReactNode => {
    const kind = options.kind

    switch (kind) {
      case 'white':
        return <MercuryLogoWordmark />
      case 'colored':
      case 'black':
        return <MercuryLogoWordmark />
      case 'inherit':
        return <MercuryLogoWordmark />
      default:
        return unreachableCase(kind)
    }
  })()

  return (
    <ContextMenu.Root>
      <ContextMenu.Trigger className={className}>
        {clickable ? (
          <NoStyleLink href={internalLinks.home}>
            <div className={wrapperDivClassNames}>{logo}</div>
          </NoStyleLink>
        ) : (
          <div className={wrapperDivClassNames}>{logo}</div>
        )}
      </ContextMenu.Trigger>
      <ContextMenu.Portal>
        <ContextMenu.Content
          className={classnames(styles.contextMenuContent, arcadia.className)}
        >
          <ContextMenu.Item
            className={styles.contextMenuItem}
            onSelect={() => {
              download(
                'mercury-logo-icon.svg',
                `${LOGO_DOWNLOAD_URL_PREFIX}/mercury-logo-icon.svg`,
                'homepageIconDownload'
              )
            }}
          >
            <FontAwesomeIcon
              icon={faArrowDownToLine}
              className={styles.contextMenuIcon}
            />{' '}
            Download icon as .svg
          </ContextMenu.Item>
          <ContextMenu.Item
            className={styles.contextMenuItem}
            onSelect={() => {
              download(
                'mercury-logo-icon-and-wordmark.svg',
                `${LOGO_DOWNLOAD_URL_PREFIX}/mercury-logo-icon-and-wordmark.svg`,
                'homepageFullLogoDownload'
              )
            }}
          >
            <FontAwesomeIcon
              icon={faArrowDownToLine}
              className={styles.contextMenuIcon}
            />{' '}
            Download full logo as .svg
          </ContextMenu.Item>
          <ContextMenu.Separator className={styles.contextMenuSeparator} />
          <ContextMenu.Item
            className={styles.contextMenuItem}
            onSelect={() => {
              download(
                'mercury-logos.zip',
                externalLinks.logoDownloads.mercuryBrandKitZip,
                'homepageLogoZipDownload'
              )
            }}
          >
            <FontAwesomeIcon
              icon={faFileZipper}
              className={styles.contextMenuIcon}
            />{' '}
            Download brand kit as .zip
          </ContextMenu.Item>
        </ContextMenu.Content>
      </ContextMenu.Portal>
    </ContextMenu.Root>
  )
}
