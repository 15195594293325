import {unreachableCase} from '~/design-system/utils'
import {env, mercuryDomain} from './env.mjs'

export const demoUrl = `https://demo.${mercuryDomain}`

export const mercuryWebBackendUrl = (() => {
  switch (env.NEXT_PUBLIC_BACKEND_SOURCE) {
    case 'localhost':
      return `http://localhost:3000`
    case 'mock':
      return ''
    case 'production':
      return `https://backend.${mercuryDomain}`
    case 'staging':
      return `https://backend-staging.${mercuryDomain}`
    default:
      unreachableCase(env.NEXT_PUBLIC_BACKEND_SOURCE)
      return ''
  }
})()
