const WHITE_SPACES = [
  ' ',
  '\n',
  '\r',
  '\t',
  '\f',
  '\v',
  '\u00A0',
  '\u1680',
  '\u180E',
  '\u2000',
  '\u2001',
  '\u2002',
  '\u2003',
  '\u2004',
  '\u2005',
  '\u2006',
  '\u2007',
  '\u2008',
  '\u2009',
  '\u200A',
  '\u2028',
  '\u2029',
  '\u202F',
  '\u205F',
  '\u3000',
]

const NON_PRINTABLE_CHARS = [
  '\x00',
  '\x01',
  '\x02',
  '\x03',
  '\x04',
  '\x05',
  '\x06',
  '\x07',
  '\x08',
  '\x09',
  '\x0A',
  '\x0B',
  '\x0C',
  '\x0D',
  '\x0E',
  '\x0F',
  '\x10',
  '\x11',
  '\x12',
  '\x13',
  '\x14',
  '\x15',
  '\x16',
  '\x17',
  '\x18',
  '\x19',
  '\x1A',
  '\x1B',
  '\x1C',
  '\x1D',
  '\x1E',
  '\x1F',
]

const DEFAULT_CHARS_TO_TRIM = [...WHITE_SPACES, ...NON_PRINTABLE_CHARS]

/**
 * Remove any occurrence of any of the given characters from the beginning of the given string.
 * The set of characters to remove is defaulted to known whitespace and non-printable characters.
 */
export const ltrim = (str: string, chars: string[] = DEFAULT_CHARS_TO_TRIM) => {
  let start = 0
  const len = str.length
  const charLen = chars.length
  let found = true
  let i
  let c

  while (found && start < len) {
    found = false
    i = -1
    c = str.charAt(start)

    while (++i < charLen) {
      if (c === chars[i]) {
        found = true
        start++
        break
      }
    }
  }

  return start >= len ? '' : str.substr(start, len)
}

/**
 * Remove any occurrence of any of the given characters from the end of the given string.
 * The set of characters to remove is defaulted to known whitespace and non-printable characters.
 */
export const rtrim = (str: string, chars: string[] = DEFAULT_CHARS_TO_TRIM) => {
  let end = str.length - 1
  const charLen = chars.length
  let found = true
  let i
  let c

  while (found && end >= 0) {
    found = false
    i = -1
    c = str.charAt(end)

    while (++i < charLen) {
      if (c === chars[i]) {
        found = true
        end--
        break
      }
    }
  }

  return end >= 0 ? str.substring(0, end + 1) : ''
}

/**
 * Remove any occurrence of any of the given characters from the beginning & end of the given string.
 * The set of characters to remove is defaulted to known whitespace and non-printable characters.
 */
export const trim = (str: string, chars: string[] = DEFAULT_CHARS_TO_TRIM) =>
  ltrim(rtrim(str, chars), chars)
