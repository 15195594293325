import classnames from 'classnames'
import React from 'react'
import styles from './styles.module.css'
import upperFirst from '~/utils/upperFirst'
import MWDSLink, {
  type MWDSLinkBaseProps,
} from '~/mercuryWebCompat/design-system/MWDSLink'

type MWDSButtonLinkVariant = 'primary' | 'secondary' | 'tertiary'
type MWDSButtonLinkSize = 'big' | 'little'
type MWDSButtonColorScheme = 'light' | 'dark'

type MWDSButtonLinkProps = {
  variant: MWDSButtonLinkVariant
  size: MWDSButtonLinkSize
  colorScheme?: MWDSButtonColorScheme
  children: React.ReactNode
  storybookForce?: 'hover' | 'focus'
  external?: boolean
  className?: string
} & Pick<
  MWDSLinkBaseProps,
  | 'color'
  | 'wrapperClassName'
  | 'div'
  | 'href'
  | 'analyticsEvent'
  | 'newTab'
  | 'prefetch'
  | 'onClick'
>

const MWDSButtonLink = ({
  variant,
  size,
  children,
  className,
  colorScheme = 'light',
  storybookForce,
  external,
  color,
  ...MWDSLinkProps
}: MWDSButtonLinkProps) => {
  return (
    <MWDSLink
      {...MWDSLinkProps}
      color={color}
      underline="never"
      className={classnames(
        styles.button,
        styles[variant],
        styles[size],
        color && styles[color],
        colorScheme && styles[colorScheme],
        storybookForce && styles[`force${upperFirst(storybookForce)}`],
        className
      )}
    >
      {children}
    </MWDSLink>
  )
}

export default MWDSButtonLink
