import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
} from '@fortawesome/pro-regular-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styles from './styles.module.css'

export type ChevronPosition = 'left' | 'right' | 'up' | 'down'

type ChevronWrapperProps = {
  children: React.ReactNode
  chevron?: ChevronPosition
}

const ChevronWrapper = ({chevron, children}: ChevronWrapperProps) => {
  return (
    <>
      {chevron === 'left' && (
        <FontAwesomeIcon className={styles.iconLeft} icon={faChevronLeft} />
      )}
      {children}
      {chevron === 'right' && (
        <FontAwesomeIcon className={styles.iconRight} icon={faChevronRight} />
      )}
      {chevron === 'up' && (
        <FontAwesomeIcon className={styles.iconUp} icon={faChevronUp} />
      )}
      {chevron === 'down' && (
        <FontAwesomeIcon className={styles.iconDown} icon={faChevronDown} />
      )}
    </>
  )
}

export default ChevronWrapper
