import {Analytics} from './Analytics'

export const downloadPDF = (
  filename: string,
  href: string,
  analyticsEvent?: string,
  newTab?: boolean
) => {
  if (analyticsEvent) {
    Analytics.track('download PDF', {downloadName: analyticsEvent})
  }
  download(filename, href, undefined, newTab)
}

export const download = (
  filename: string,
  href: string,
  analyticsEvent?: string,
  newTab?: boolean
) => {
  if (analyticsEvent) {
    Analytics.track('download', {downloadName: analyticsEvent})
  }

  // Note: This is not supported by IE and Opera Mini
  const link = document.createElement('a')
  link.download = filename
  link.href = href

  // append / remove required for Firefox: https://stackoverflow.com/a/27116581/2544629
  document.body.appendChild(link)
  if (newTab) {
    window.open(link.href, '_blank')
  } else {
    link.click()
  }
  document.body.removeChild(link)
}
