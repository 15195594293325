'use client'

import {Button, type ButtonProps} from 'ariakit'
import classnames from 'classnames'
import Link, {type LinkProps} from 'next/link'
import React from 'react'
import {type OmitStrict} from 'type-zoo'
import {type BaseColorName} from '~/mercuryWebCompat/tokens/colors/baseColors'
import {type FemSemanticColorName} from '~/mercuryWebCompat/tokens/colors/femSemanticColors'
import {colorNameToColorClass} from '~/mercuryWebCompat/tokens/colors/utils'
import {Analytics} from '~/utils/Analytics'
import {type ClickEvent} from '~/utils/Analytics/types'
import {MWDSTextDiv, type MWDSTextDivProps} from '../MWDSText'
import ChevronWrapper, {type ChevronPosition} from './ChevronWrapper'
import styles from './styles.module.css'

type LinkUnderlineOptions = 'never' | 'always' | 'onHover' | boolean

type MWDSLinkVariant =
  | 'primary'
  | 'secondary'
  | 'inline-primary'
  | 'inline-secondary'
  | 'custom'
type MWDSLinkSize = 'default' | 'small' | 'inherit'

export type MWDSLinkBaseProps = LinkProps &
  OmitStrict<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & {
    analyticsEvent?: ClickEvent
    /**
     * If supplied, a chevron (like a `<`) will be displayed next to the Link in the chosen position.
     * Use this for directional Links.
     */
    chevron?: ChevronPosition
    color?: BaseColorName | FemSemanticColorName
    div?: boolean
    newTab?: boolean
    onClick?: LinkProps['onClick']
    size?: MWDSLinkSize
    underline?: LinkUnderlineOptions
    variant?: MWDSLinkVariant
    wrapperClassName?: string
  }

export default function MWDSLink({
  analyticsEvent,
  href,
  newTab,
  onClick,
  rel,
  target,
  prefetch,
  color,
  className,
  wrapperClassName,
  chevron,
  underline = 'onHover',
  // TODO: figure out if div prop is actually necessary w/ next/link implementation
  div,
  ...linkProps
}: MWDSLinkBaseProps) {
  const {children, ...restLinkProps} = linkProps
  return React.createElement(
    div ? 'div' : 'span',
    {
      className: classnames(color && colorNameToColorClass(color), wrapperClassName),
    },
    <Link
      {...restLinkProps}
      href={href}
      prefetch={prefetch}
      className={classnames(
        className,
        styles.link,
        underline !== undefined && styles[`underline-${underline.toString()}`],
        styles.noVisitedColoring,
        'hoverable'
      )}
      target={newTab ? '_blank' : target}
      rel={newTab ? `noopener noreferrer${rel ? ` ${rel}` : ''}` : rel}
      onClick={event => {
        const linkEl = event.target as HTMLElement
        analyticsEvent &&
          Analytics.trackClick(
            Analytics.mergeProperties(analyticsEvent, {
              text: linkEl.innerText,
              linkedPath: href,
            })
          )
        onClick?.(event)
      }}
    >
      <ChevronWrapper chevron={chevron}>{children}</ChevronWrapper>
    </Link>
  )
}

export type MWDSExternalLinkWithDefaultProtocolProps = OmitStrict<
  MWDSLinkBaseProps,
  'href'
> & {href: string}

// Used for some admin pages showing user-generated content that might've
// forgotten the protocol, e.g. 'foo.com'.
// When the protocol is missing, the browser will treat the URL as relative to
// the current domain, e.g. https://mercury.com/foo.com.
export const MWDSExternalLinkWithDefaultProtocol = (
  props: MWDSExternalLinkWithDefaultProtocolProps
) => {
  const prefixRegexpPattern = /^((http|https|ftp):\/\/)/
  const hrefWithProtocol = prefixRegexpPattern.test(props.href)
    ? props.href
    : `http://${props.href}`

  return (
    <MWDSLink {...props} href={hrefWithProtocol}>
      {props.children}
    </MWDSLink>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type MWDSLinkTextDivProps = {} & OmitStrict<
  MWDSLinkBaseProps,
  'newTab' | 'color' | 'variant' | 'size' | 'onClick'
> &
  OmitStrict<MWDSTextDivProps, 'underline' | 'onClick' | 'as' | 'children'> &
  OmitStrict<ButtonProps<'div'>, 'color' | 'as'>

/** Wraps `<DSTextDiv>` so we can style it like a link */
export const MWDSLinkTextDiv = ({
  className,
  underline,
  chevron,
  analyticsEvent,
  onClick,
  children,
  onFocusCapture,
  ...dsTextProps
}: MWDSLinkTextDivProps) => (
  <Button
    // TODO: debug type error here
    as={MWDSTextDiv as any}
    {...dsTextProps}
    onFocusCapture={(event: React.FocusEvent<HTMLDivElement>) => {
      // ariakit expects react 17+ which does not do event pooling but we use react 16.14 (as of Jan 23 2023) which does
      // this mimics the new behavior, otherwise ariakit will trigger warnings from react
      event.persist()
      onFocusCapture?.(event)
    }}
    onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const linkEl = event.target as HTMLElement
      analyticsEvent &&
        Analytics.trackClick(
          Analytics.mergeProperties(analyticsEvent, {text: linkEl.innerText})
        )

      onClick?.(event)
    }}
    className={classnames([
      className,
      styles.link,
      underline !== undefined && styles[`underline-${underline.toString()}`],
    ])}
  >
    <ChevronWrapper chevron={chevron}>{children}</ChevronWrapper>
  </Button>
)
