import {unreachableCase} from '~/design-system/utils'
import {env} from '../config/env.mjs'

export const FEM_ANONYMOUS_ID_COOKIE_NAME = 'fem_anonymous_user_id'
export const GA_COOKIE_NAME = '_ga'
export const FACEBOOK_PIXEL_COOKIE_NAME = '_fbp'
export const XSRF_TOKEN_COOKIE_NAME = 'XSRF-TOKEN'
export const TRACKING_OPTOUT_COOKIE_NAME = 'tracking-optout'

/**
 * Copied from https://github.com/MercuryTechnologies/mercury-web/blob/master/src/routing/subdomain/useAutoRedirectToAppSubdomain.ts#L16
 * with some updates to reflect that we don't have all the same env/ backend sources that `mercury-web` does
 */
export function getUserLoggedInCookieKey() {
  const USER_LOGGED_IN_COOKIE_KEY = 'userLoggedIn'

  switch (env.NEXT_PUBLIC_BACKEND_SOURCE) {
    case 'production':
      return USER_LOGGED_IN_COOKIE_KEY
    case 'staging':
    case 'localhost':
    case 'mock':
      return `${USER_LOGGED_IN_COOKIE_KEY}_${env.NEXT_PUBLIC_BACKEND_SOURCE}`
    // NOTE: The following cases do not have a cookie key because they don't communicate with the backend
    default:
      unreachableCase(env.NEXT_PUBLIC_BACKEND_SOURCE)
      return ''
  }
}
