import * as R from 'ramda'
import {trim} from './trim'
import * as RA from 'ramda-adjunct'

export const isEmpty = (str: string | undefined | null): boolean => {
  return R.isNil(str) || trim(str).length === 0
}

/** Checks if the parameter is null/undefined or empty (whitespace-only strings are considered 'empty') */
export const isPresent = <T extends NonNullable<string>>(
  str: T | undefined | null
): str is NonNullable<T> => {
  return !isEmpty(str)
}

/**
 * Remove all falsey values from a list. 0, '', false, undefined, and null will be removed.
 * (RA's compact isn't typed nicely for uniform lists. This wrapper is.)
 */
export const compact = <T>(
  list: T[]
): Array<NonNullable<Exclude<T, 0 | '' | false>>> => {
  return RA.compact(list)
}
