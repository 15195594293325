import classnames from 'classnames'
import Link, {type LinkProps} from 'next/link'
import styles from './styles.module.css'
import {type PropsWithChildren} from 'react'
import {type OmitStrict} from 'type-zoo'

type NoStyleLinkProps = LinkProps &
  OmitStrict<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>

/** Wraps `Link` from next-link so we can style it without a global `<a>` tag style.
 * This component removes the browser's <a> default styles for text
 */
export const NoStyleLink = ({
  children,
  className,
  ...linkProps
}: PropsWithChildren<NoStyleLinkProps>) => (
  <Link {...linkProps} className={classnames(styles.noStyleLink, className)}>
    {children}
  </Link>
)
